import React, { useEffect, useState } from 'react';
import './styles.scss';
import { submit_contact_use_form } from '@/components/ContactUs/services/contactus.service';

interface FormFields {
  name: string;
  email: string;
  message: string;
}

const ContactUs = ({ isMobileView, setLoading }: any) => {
  const [formFields, setFormFields] = useState<FormFields>({ name: '', email: '', message: '' });
  const [errors, setErrors] = useState<FormFields>({ name: '', email: '', message: '' });
  const [responseMessage, setResponseMessage] = useState('')

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormFields((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' })); // Clear the error on change
    setResponseMessage('')
  };

  // Validate the form fields
  const validateFields = () => {
    const newErrors: FormFields = { name: '', email: '', message: '' };
    let isValid = true;

    if (!formFields.name) {
      newErrors.name = 'Please enter a valid name';
      isValid = false;
    }

    if (!formFields.email) {
      newErrors.email = 'Please enter a valid email address';
      isValid = false;
    }

    if (!formFields.message) {
      newErrors.message = 'Please write a message';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // Handle form submission
  const handleSubmit = async (event: any) => {
    event.preventDefault(); // Prevent default form submission behavior
    if (validateFields()) {
      try {
        setLoading(true);
        const response = await submit_contact_use_form(formFields);
        if (response?.data?.status === 'success') {
          setResponseMessage(response?.data?.message)
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };


  return (
    <>
      {isMobileView ? (
        <section className='contact-use-section' id='overview'>
          <div className='liner-shade'>
            <div className='main-container'>
              <h3>Contact Our Support Team</h3>
              <p>
                If you have any questions or need assistance our support team will help you every step of the way.
              </p>
            </div>
            <div className="form-container">
              <div className='success-message'>{responseMessage}</div>
              <form onSubmit={handleSubmit}>
                <div className="form-input-container">
                  <div className="form-group">
                    <label className='input-text'>Name</label>
                    <div className='input-field' >
                      <input type="text" id="name" name="name" value={formFields.name} placeholder="Enter your name" onChange={handleChange} />
                    </div>
                    {errors.name && <label className='error-message'>{errors.name}</label>}
                  </div>
                  <div className="form-group">
                    <label className='input-text'>Email Address</label>
                    <div className='input-field' >
                      <input type="email" id="email" name="email" value={formFields.email} placeholder="Enter your email address" onChange={handleChange} />
                    </div>
                    {errors.email && <label className='error-message'>{errors.email}</label>}
                  </div>
                </div>

                <div className="form-group message-container">
                  <label htmlFor="message" className='input-text'>Message</label>
                  <div className='message-input-field'>
                    <textarea id="message" name="message" value={formFields.message} placeholder="Your message here" onChange={handleChange}></textarea>
                  </div>
                  {errors.message && <label className='error-message'>{errors.message}</label>}
                </div>
                <button type="submit" className="submit-button"> <span>Send Message</span></button>
              </form>
            </div>
          </div>
        </section>
      ) : (
        <section className='contact-use-section' id='overview'>
          <div className='liner-shade'>
            <div className='main-container'>
              <h3>Contact Our Support Team</h3>
              <p>
                If you have any questions or need assistance our support team will help you every step of the way.
              </p>
            </div>
            <div className="form-container">
              <div className='success-message'>{responseMessage}</div>
              <form onSubmit={handleSubmit}>
                <div className="form-input-container">
                  <div className="form-group">
                    <label className='input-text'>Name</label>
                    <div className='input-field' >
                      <input type="text" id="name" name="name" value={formFields.name} placeholder="Enter your name" onChange={handleChange} />
                    </div>
                    {errors.name && <label className='error-message'>{errors.name}</label>}
                  </div>
                  <div className="form-group">
                    <label className='input-text'>Email Address</label>
                    <div className='input-field' >
                      <input type="email" id="email" name="email" value={formFields.email} placeholder="Enter your email address" onChange={handleChange} />
                    </div>
                    {errors.email && <label className='error-message'>{errors.email}</label>}
                  </div>
                </div>

                <div className="form-group message-container">
                  <label htmlFor="message" className='input-text'>Message</label>
                  <div className='message-input-field'>
                    <textarea id="message" name="message" value={formFields.message} placeholder="Your message here" onChange={handleChange}></textarea>
                  </div>
                  {errors.message && <label className='error-message'>{errors.message}</label>}
                </div>
                <button type="submit" className="submit-button"> <span>Send Message</span></button>
              </form>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ContactUs;
