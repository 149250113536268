// import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';

const Logo = ({ isMobileView }: any) => {
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    // Since useEffect runs on the client side, it's safe to use navigator here
    const userAgent = window.navigator.userAgent;
    const safari =
      userAgent.includes('Safari') && !userAgent.includes('Chrome');
    setIsSafari(safari);
  }, []);

  // Determine the file extension based on the browser
  const fileExtension = isSafari ? 'png' : 'svg';
  console.log(fileExtension, isSafari);

  // Determine the logo based on the view
  const logoSrc = isMobileView
    ? `/images/logo-mobile.${fileExtension}`
    : `/images_p/logo.${fileExtension}`;

  // Determine the logo dimensions
  const logoDimensions = isMobileView
    ? { width: 80, height: 30 }
    : { width: 123, height: 49 };

  return (
    <Link href='/'>
      <img
        src={logoSrc}
        alt='Logo'
        width={logoDimensions.width}
        height={logoDimensions.height}
        className='relative'
        loading='lazy'
      />
    </Link>
  );
};

export default Logo;
