import React from "react";
import styles from './styles.module.scss';
interface ILoaderProps {
  isLoading: boolean
}
const Loader: React.FC<ILoaderProps> = (props) => {
  if (!props.isLoading) {
    return null; // Return null if loader is not active
  }
  if (props.isLoading) {
    return (
      <div className={styles.loaderOverlay}>
        <img src="/images/loader.png" width="100" height="100" alt="loader" />
      </div>
    );
  }
}
export default Loader;

