import { apiUrl } from '@/apiUrl';
import { http_post } from '@/lib/http';
export const submit_contact_use_form = async (requestDate: any) => {
  const resp = await http_post(
    apiUrl + `/support`, requestDate
  );
  return resp;
};
export default function Home1() {
  return null;
}
